import { setComponentMapOverrides } from '@jutro/uiconfig';

import IMDummyPage from './pages/Dummy/IMDummyPage';
import IMDummyReadOnlyPage from './pages/Dummy/IMDummyPage.readonly';

import IMUnderwritingQuestionsPage from './pages/UnderwritingQuestions/IMUnderwritingQuestionsPage';
import IMUnderwritingQuestionsReadOnlyPage from './pages/UnderwritingQuestions/IMUnderwritingQuestionsPage.readonly';

import IMQuotePage  from './pages/Quote/IMQuotePage';
import IMQuoteReadOnlyPage from './pages/Quote/IMQuotePage.readonly';

import IMModifiersPage from './pages/Modifiers/IMModifiersPage';
import IMModifiersReadOnlyPage from './pages/Modifiers/IMModifiersPage.readonly';

setComponentMapOverrides(
    {
        IMDummyPage: {component: 'IMDummyPage'},
        IMDummyReadOnlyPage: { component: 'IMDummyReadOnlyPage' },

        IMUnderwritingQuestionsPage: {component: 'IMUnderwritingQuestionsPage'},
        IMUnderwritingQuestionsReadOnlyPage: { component: 'IMUnderwritingQuestionsReadOnlyPage' },

        //
        IMQuotePage: {component: 'IMQuotePage'},
        IMQuoteReadOnlyPage: {component: 'IMQuoteReadOnlyPage'},

        IMModifiersPage: {component: 'IMModifiersPage'},
        IMModifiersReadOnlyPage: {component: 'IMModifiersReadOnlyPage'},
    },
    {
        IMDummyPage,
        IMDummyReadOnlyPage,

        IMUnderwritingQuestionsPage,
        IMUnderwritingQuestionsReadOnlyPage,

        //
        IMQuotePage,
        IMQuoteReadOnlyPage,

        IMModifiersPage,
        IMModifiersReadOnlyPage
    }
);


export {
    IMDummyPage,
    IMDummyReadOnlyPage,
    IMModifiersPage,
    IMModifiersReadOnlyPage,
    IMUnderwritingQuestionsPage,
    IMUnderwritingQuestionsReadOnlyPage,
    //
};

 
export { default as IMSubmissionWizard } from './IMSubmissionWizard';
export { default as IMWizardPage } from './templates/IMWizardPage';

export { default as IMQuoteIssueRenderHelper } from './pages/Quote/util/IMQuoteIssueRenderHelper';
export { default as IMQuotePageConstants } from './pages/Quote/config/IMQuotePage.config';
