import React, {
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import {
    CurrencyField,
    FileUploadField,
    Button
} from '@jutro/components';
import { WniTableRowUtil } from 'wni-portals-util-react';
import {
    WniDateUtil,
    DocumentsUtil
} from 'wni-portals-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {  useWniModal } from 'wni-components-platform-react';
import ManualLossComponent from "wni-capability-gateway-react/components/ManualLossComponent/ManualLossComponent";
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import {
    WniSubmissionService
} from 'wni-capability-gateway';
import WizardPage from '../../templates/CPPWizardPage';

import styles from './CPPRiskAnalysisPage.module.scss';
import metadata from './CPPRiskAnalysisPage.metadata.json5';
import messages from './CPPRiskAnalysisPage.messages';

function CPPRiskAnalysisReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        resetWizardDataToSnapshot,
        lobName
    } = props;
    const modalApi = useWniModal();
    const translator = useTranslator();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const { authHeader, authUserData: {displayName: currentUser} } = useAuthentication();
    const {
        jobID,
        lobData: {
            [lobName]: {
                riskAnalysis_Ext: {
                    lossHistoryType
                }
            }
        }
    } = submissionVM.value;

    const defaultValidationInfo = useMemo(() => {
        return [{
        type: 'info',
        reason: translator(messages.priorLossInfo)
    }]}, [translator]);
    const manualLossVMPath = `lobData.${lobName}.riskAnalysis_Ext.manualLoss`;
    const manualLossVM = _.get(submissionVM, manualLossVMPath);
    const manualLoss = _.get(manualLossVM, 'value', []); 
    const [selection, updateSelection] = useState([]);
    const [currentRow, updateCurrentRow] = useState(null);
    const [initDocuments, updateInitDocuments] = useState([]);

    const handleError = useCallback((messageTitle, message) => {
        return modalApi.showAlert({
            title: messageTitle,
            message: message,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).then(_.noop, _.noop);

    }, []);

    const retrieveInitSubmissionDocAndAttachments = useCallback(async () => {
        try {
            setLoadingMask(true);
            const response =
                await WniSubmissionService.getDocsAndAttachsForJob(
                    [jobID, true],
                    authHeader
                );
            setLoadingMask(false);    
            const documents = DocumentsUtil.updateDocumentUnselectable(response);
            const lossTypeDocuments = documents.filter(doc => doc.securityType === "unrestricted" && doc.documentType === "loss_history" && doc.author === currentUser);
            updateInitDocuments(lossTypeDocuments);
        } catch (e) {
            handleError(messages.modalError, messages.errorLoadingDocument);
        }
    }, [authHeader, currentUser, handleError, jobID, setLoadingMask]);

    useEffect(() => {
        if (lossHistoryType === 'att') {
            retrieveInitSubmissionDocAndAttachments()
        }
    }, [lossHistoryType])

    const cancelLoss = useCallback(() => {
        resetWizardDataToSnapshot();
        updateCurrentRow(null);
    }, [resetWizardDataToSnapshot]);
    
    const writeValue = (value, path) => {
        _.set(submissionVM.value, path, value);
        updateSubmissionVM(submissionVM);
    };

    const onValueChange = useCallback((value, path) => {
        _.set(currentRow.value, path, value);
        updateCurrentRow(currentRow);
        updateSubmissionVM(submissionVM);
    },[currentRow, submissionVM, updateSubmissionVM]);

    const viewOrEditLoss = useCallback((value, index) => {
        const childrenVM = _.get(submissionVM, `${manualLossVMPath}.children`);
        const lossVM = childrenVM.find((item) => item.value.publicId === index);
        updateCurrentRow(lossVM);
    }, [submissionVM]);

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'publicId') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'manualLossTable');
    };

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow]);

    const onPageNext = useCallback(() => {
        return submissionVM;
    }, [submissionVM]);

    const onColumnCell = useCallback((item, index, property) => {
        const { path, typeKey } = property;
        if (item[path] && item[path].year) {
            return (
                <div>{WniDateUtil.formatDateWithPattern(item[path])}</div>
            );
        }
        if (item[path] && item[path].currency) {
            return (
                <CurrencyField
                    id={`currency_${index}`}
                    value={item[path]}
                    readOnly
                    hideLabel
                    showOptional={false}
                />
            );
        }
        const val = typeKey
            ? translator({ id: `${typeKey}.${item[path]}` })
            : item[path];
        return WniTableRowUtil.renderCell(item.publicId, `${item[path] ? val : '-'}`)
    }, [translator]);

    const renderUploadDocBtn = () => {
        return (
            <div className={`${styles.height35} uploadWrapper` }>   
                <FileUploadField
                    disabled
                    disableDragAndDrop
                    hideLabel
                    buttonType="outlined"
                    className="uploadRealBtn"
                />
                <Button
                    disabled
                    type="outlined"
                    className="uploadMaskBtn uploadBtn"
                >
                    Upload File
                </Button>
            </div>
        )
    }

    const getNameLink = (item, index, property) => {
        const { path } = property;
        return (
            <div className={styles.documentName}>
                {item[path]}
            </div>
        );
    };

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left',
                disabled: true
            },
            lossHistoryTypeDropdown: {
                path: `lobData.${lobName}.riskAnalysis_Ext.lossHistoryType`
            },
            manualLossDetailBtns: {
                visible: !_.isEmpty(currentRow)
            },
            uploadButton: {
                disabled: true
            },
            LossHistoryAttachedSection: {
                visible: lossHistoryType === 'att'
            },
            AttachedLossRunsSection: {
                visible: lossHistoryType === 'att'
            },
            attachedLossRunsTable: {
                data: initDocuments
            },
            uploadAction: {
                header: renderUploadDocBtn()
            },
            manualLossTableHeader: {
                visible: lossHistoryType === 'man'
            },
            numberOfLossesInput: {
                required: lossHistoryType === 'att',
                showRequired: lossHistoryType === 'att',
                readOnly: true,
                path: `lobData.${lobName}.riskAnalysis_Ext.attachedLoss.numberOfLosses`
            },
            totalIncurredInput: {
                required: lossHistoryType === 'att',
                showRequired: lossHistoryType === 'att',
                readOnly: true,
                path: `lobData.${lobName}.riskAnalysis_Ext.attachedLoss.attachedTotalIncurred`
            },
            addLoss: {
                disabled: true,
            },
            delLoss: {
                disabled: true
            },
            viewOrEditLink: {
                onClick: viewOrEditLoss,
                disabled: currentRow,
                label: {
                    defaultMessage: "View"
                }
            },
            manualLossTable: {
                data: manualLoss,
                visible: lossHistoryType === 'man',
                onSelectionChange: (rows) => {
                    updateSelection(rows)
                },
                rowIdPath: 'publicId',
                selectionType: 'none'
            },
            detailedLossPanel: {
                visible: !_.isEmpty(currentRow),
                lossVM: currentRow,
                onValueChange,
                isReadOnly: true,
                updateCurrentRow,
                hideCategory: true
            },
            saveNextButton: {
                disabled: true
            },
            saveButton: {
                disabled:true
            }
        };
    }, [currentRow, initDocuments, lobName, lossHistoryType, manualLoss, onValueChange, viewOrEditLoss]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onColumnCell,
            cancelLoss,
            getNameLink
        },
        resolveComponentMap: {
            manualLossComponent : ManualLossComponent
        }
    };

    return (
        <WizardPage
            showNext={!currentRow}
            showPrevious={!currentRow}
            showCancel={!currentRow}
            onNext={onPageNext}
            pageLevelValidationIssues={defaultValidationInfo}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

CPPRiskAnalysisReadOnlyPage.propTypes = {
    ...wizardProps,
    lobName: PropTypes.string
};
CPPRiskAnalysisReadOnlyPage.defaultProps = {
    lobName: 'commercialPackage'
}
export default CPPRiskAnalysisReadOnlyPage;