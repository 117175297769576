import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cppRiskAnalysisService'), method, data, additionalHeaders);
}

export default class CPPRiskAnalysisService {
    /**
         * Invokes CPPRiskAnalysisHandler.saveNonLossType
         * 
         * @param {string} quoteID 
         * @param {string} sessionUUID 
         * @param {Object} authHeader 
         * @returns {Promise}
         */
    static saveNonLossType(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('saveNonLossType', [quoteID, sessionUUID], authHeader);
    }

    /** 
         * Invokes CPPRiskAnalysisHandler.savePrevUploadType
         * 
         * @param {string} quoteID 
         * @param {string} sessionUUID 
         * @param {Object} authHeader 
         * @returns {Promise}
         */
    static savePrevUploadType(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission('savePrevUploadType', [quoteID, sessionUUID], authHeader);
    }

    /**
     * Invokes CPPRiskAnalysisHandler.saveAttachedLoss
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static saveAttachedLoss(quoteID, sessionUUID, newModel, authHeader = {}) {
        return processSubmission('saveAttachedLoss', [quoteID, sessionUUID, newModel], authHeader);
    }

    /**
     * Invokes CPPRiskAnalysisHandler.saveManualLoss
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} newModel
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static saveManualLoss(quoteID, sessionUUID, newModel, authHeader = {}) {
        return processSubmission('saveManualLoss', [quoteID, sessionUUID, newModel], authHeader);
    }

    /**
     * Invokes CPPRiskAnalysisHandler.updateManualLoss
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} newModel
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateManualLoss(quoteID, sessionUUID, newModel, authHeader = {}) {
        return processSubmission('updateManualLoss', [quoteID, sessionUUID, newModel], authHeader);
    }

    /**
     * Invokes CPPRiskAnalysisHandler.deleteManualLoss
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {Object} newModel
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static deleteManualLoss(quoteID, sessionUUID, newModel, authHeader = {}) {
        return processSubmission('deleteManualLoss', [quoteID, sessionUUID, newModel], authHeader);
    }
}