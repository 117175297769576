import { Button } from '@jutro/components';
import { DisplayColumn } from '@jutro/datatable';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import classNames from 'classnames';
import React, { useState } from 'react';
import {
    WniCheckboxField,
    WniDataTable,
    WniDropdownSelect,
    WniInputText,
    WniRadio,
} from 'wni-common-base-components';
import { useWniModal } from 'wni-components-platform-react';
import messages from './MortgageeDetails.messages';
import styles from './MortgageeDetails.module.scss';
import SearchFromAddressBookPopup from './SearchFromAddressBookPopup/SearchFromAddressBookPopup';

const NewContactType = {
    Company: 'company',
    Person: 'person',
};

const ContractNumberCell = (props) => {
    const {
        isEditable,
        contractNumber: originContractNumber,
        additionalInterest,
        updateAdditionalInterest,

    } = props;
    const [contractNumber, setContractNumber] = useState(originContractNumber);
    if (!isEditable) {
        return contractNumber;
    }
    return (
        <WniInputText
            value={contractNumber}
            onValueChange={(newValue) => {
                setContractNumber(newValue);
            }}
            onBlur={() => {
                updateAdditionalInterest({
                    ...additionalInterest,
                    contractNumber: contractNumber,
                });
            }}
        />
    );
};

const MortgageeDetails = (props) => {
    const translator = useTranslator();
    const modalApi = useWniModal();
    const [
        selectedAdditionalInterestFixedIDs,
        setSelectedAdditionalInterestFixedIDs,
    ] = useState([]);

    const {
        mortgageeDetails,
        updateMortGageeDetails,
        searchAddressBookFunc,
        addNewSelectContactInfoMortgageeWhileUpdateMortgageeFunc,
        addNewContact,
        isEditable,
        onValidate,
        showErrors,
    } = props;

    const {
        mortgageeDetails: mortgageeDetailsSelected,
        additionalInterests = [],
    } = mortgageeDetails;

    const updateAdditionalInterests = (newAdditionalInterests) => {
        updateMortGageeDetails({
            mortgageeDetails: mortgageeDetailsSelected,
            additionalInterests: newAdditionalInterests,
        });
    };

    const updateAdditionalInterest = (newAdditionalInterest) => {
        const newAdditionalInterests = additionalInterests.map(
            (additonalInterest) => {
                if (
                    additonalInterest.fixedId === newAdditionalInterest.fixedId
                ) {
                    return newAdditionalInterest;
                }
                return additonalInterest;
            }
        );
        updateAdditionalInterests(newAdditionalInterests);
    };

    const checkboxStyles = classNames({
        [styles.clauseNoPadding]: false,
    });

    const handleDeleteBtnClicked = () => {
        updateAdditionalInterests(
            additionalInterests.filter(
                (additionalInterest) =>
                    !selectedAdditionalInterestFixedIDs.includes(
                        additionalInterest.fixedId
                    )
            )
        );
    };

    const onClickAddContact = () => {
        const componentProps = {
            size: 'lg',
            searchAddressBookFunc,
            addNewSelectContactInfoMortgageeWhileUpdateMortgageeFunc,
            mortgageeDetails,
        };
        modalApi
            .showModal(<SearchFromAddressBookPopup {...componentProps} />)
            .then()
            .catch((reason) => {
                if (reason === NewContactType.Person) {
                    addNewContact(NewContactType.Person);
                } else if (reason === NewContactType.Company) {
                    addNewContact(NewContactType.Company);
                }
            });
    };

    const renderSelectAdditionalInterestCell = (item) => {
        const { fixedId } = item;
        return (
            <WniCheckboxField
                value={selectedAdditionalInterestFixedIDs.includes(fixedId)}
                showInlineLabel
                onValueChange={(checked) => {
                    let newSelectedAdditionalInterestFixedIDs;
                    if (checked) {
                        newSelectedAdditionalInterestFixedIDs =
                            selectedAdditionalInterestFixedIDs.concat([
                                fixedId,
                            ]);
                    } else {
                        newSelectedAdditionalInterestFixedIDs =
                            selectedAdditionalInterestFixedIDs.filter(
                                (id) => id !== fixedId
                            );
                    }
                    setSelectedAdditionalInterestFixedIDs(
                        newSelectedAdditionalInterestFixedIDs
                    );
                }}
            />
        );
    };

    const existingAdditionalInterestTypes = additionalInterests.map(
        (interest) => interest.type
    );

    const renderAdditionalInterestTypeCell = (additionalInterest) => {
        const { type } = additionalInterest;
        if (!isEditable) {
            return translator({
                id: `typekey.AdditionalInterestType.${type}`,
            });
        }

        const availableValueCodes = [].concat(
            [
                'CP7FIRSTMORTGAGEE_Ext',
                'CP7SECONDMORTGAGEE_Ext',
                'CP7THIRDMORTGAGEE_Ext',
            ].filter(
                (code) =>
                    code === type ||
                    !existingAdditionalInterestTypes.includes(code)
            )
        );

        return (
            <WniDropdownSelect
                required
                value={type}
                availableValues={availableValueCodes.map((code) => ({
                    code,
                    name: translator({
                        id: `typekey.AdditionalInterestType.${code}`,
                    }),
                }))}
                onValueChange={(code) => {
                    updateAdditionalInterest({
                        ...additionalInterest,
                        type: code,
                        sendBill_Ext: null,
                    });
                }}
                onValidate={onValidate}
                showErrors={showErrors}
            />
        );
    };

    const renderSendBillEditableModeCell = (additionalInterest) => {
        const { type, sendBill_Ext: sendBill } = additionalInterest;
        if (type !== 'CP7FIRSTMORTGAGEE_Ext') {
            return null;
        }
        let radioValue;
        if (sendBill === true) {
            radioValue = 'Yes';
        } else if (sendBill === false) {
            radioValue = 'No';
        }

        return (
            <WniRadio
                required
                value={radioValue}
                isHorizontal
                availableValues={[
                    {
                        id: 'Yes',
                        displayName: 'Yes',
                    },
                    {
                        id: 'No',
                        displayName: 'No',
                    },
                ]}
                onValueChange={(val) => {
                    const newAdditonalInterest = {
                        ...additionalInterest,
                        sendBill_Ext: val === 'Yes',
                    };
                    updateAdditionalInterest(newAdditonalInterest);
                }}
                onValidate={onValidate}
                showErrors={showErrors}
            />
        );
    };

    const renderContractNumberCell = (additionalInterest) => {
        const { contractNumber } = additionalInterest;

        if (!isEditable) {
            return contractNumber;
        }
        return (
            <ContractNumberCell
                isEditable={isEditable}
                contractNumber={contractNumber}
                additionalInterest={additionalInterest}
                updateAdditionalInterest={updateAdditionalInterest}
            />
        );
    };

    return (
        <>
            <WniCheckboxField
                id="MortgageeDetails"
                label={translator(messages.MortgageeDetails)}
                value={mortgageeDetailsSelected}
                readOnly={!isEditable}
                onValueChange={() =>
                    updateMortGageeDetails({
                        mortgageeDetails: !mortgageeDetailsSelected,
                    })
                }
                className={`${checkboxStyles} clauseCoverages clauseCheckbox`}
                labelPosition="right"
                showInlineLabel
                layout="full-width"
            />
            {mortgageeDetails.mortgageeDetails && (
                <>
                    {isEditable && (
                        <Flex gap="small" justifyContent="right" className="mb-10">
                                <Button
                                    className="wni-button-danger"
                                    type="filled"
                                    disabled={additionalInterests.length === 0}
                                    onClick={handleDeleteBtnClicked}
                                >
                                    {translator(messages.Delete)}
                                </Button>
                                <Button
                                    icon="gw-add"
                                    onClick={() => onClickAddContact()}
                                >
                                    {translator(messages.Add)}
                                </Button>
                        </Flex>
                    )}
                    <div className="table-wrapper mb-10">
                        <WniDataTable
                            id="additionalInterestsTable"
                            data={additionalInterests}
                            showSearch={false}
                        >
                            {isEditable && (
                                <DisplayColumn
                                    sortable={false}
                                    renderCell={
                                        renderSelectAdditionalInterestCell
                                    }
                                />
                            )}
                            <DisplayColumn
                                header={translator(messages.Name)}
                                sortable={false}
                                path="policyAdditionalInterest.displayName"
                            />
                            <DisplayColumn
                                header={translator(messages.InterestType)}
                                sortable={false}
                                renderCell={renderAdditionalInterestTypeCell}
                            />
                            {isEditable ? (
                                <DisplayColumn
                                    header={translator(messages.SendBill)}
                                    sortable={false}
                                    renderCell={renderSendBillEditableModeCell}
                                />
                            ) : (
                                <DisplayColumn
                                    header={translator(messages.SendBill)}
                                    sortable={false}
                                    path="sendBill_Ext"
                                />
                            )}
                            <DisplayColumn
                                header={translator(messages.LoanNumber)}
                                sortable={false}
                                path="contractNumber"
                                renderCell={renderContractNumberCell}
                            />
                        </WniDataTable>
                    </div>
                </>
            )}
        </>
    );
};

export default MortgageeDetails;
