import React, {
    useCallback,
    useEffect,
    useState,
    useMemo,
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { Icon } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { PortalConstants } from 'wni-portals-config-js';
import { CPStateInformationService } from 'wni-capability-quoteandbind-cp';
import { WniTableRowUtil } from 'wni-portals-util-react';
import {
    ErrorsAndWarningsUtil,
    QuoteUtil,
} from 'wni-portals-util-js';
import StateSpecificInfo from './component/CPStateSpecificInfo'
import CoveragesConfigContext from '../CommercialProperty/context/CPCoveragesConfigContext'
import CPCoveragesConfig from '../CommercialProperty/CPCoverages.config'
import WizardPage from '../../templates/CPWizardPage';
import metadata from './CPStateSpecificInformationPage.metadata.json5';
import messages from './CPStateSpecificInformationPage.messages';


const VALIDATION_ICON_MAP = {
    success: 'gw-check-circle',
    warning: 'gw-warning',
    error: 'gw-error',
};
function CPStateSpecificInformationPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        isReadOnly
    } = props;

    const {
        jobID, 
        sessionUUID,
        lobData: {
            commercialProperty: {
                coverables: {
                    stateSimpleInfos = []
                }
            }
        }
    } = submissionVM.value;

    const translator = useTranslator();

    
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const {
        initialValidation,
        onValidate,
        registerComponentValidation,
        isComponentValid,
    } = useValidation('CPStateSpecificInformationPage');
    const [validationIssues, updateValidationIssues] = useState(undefined)
    const [selectedState, setSelectedState] = useState()

    
    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );

    const isStateInfoValid = useCallback(() => {
        return stateSimpleInfos.every(info => info.isValid)
    }, [stateSimpleInfos])

    useEffect(() => {
        registerComponentValidation(isStateInfoValid)
    }, [isStateInfoValid, registerComponentValidation])

    const handleValidation = useCallback(() => {
        return false;
    }, []);

    const highlightRowFn = useCallback(() => {
        WniTableRowUtil.setTablePublicIDSelected(selectedState, 'stateSpecificTable');
    }, [selectedState])

    useEffect(() => {
        highlightRowFn();
    }, [highlightRowFn]);

    const sortColumn = (a, b, sortType) => {
        highlightRowFn();
        return DatatableUtil[sortType](a, b);
    };

    const renderValidationCell = (info) => {
        let iconDom
        if (info.isValid) {
            iconDom = <Icon id={`validationIcon${info.state}`} icon={VALIDATION_ICON_MAP.success} className='wni-icon-success' />
        } else {
            iconDom = <Icon id={`validationIcon${info.state}`} icon={VALIDATION_ICON_MAP.error} className='wni-icon-error' />
        }
        
        return WniTableRowUtil.renderCell(info.state, iconDom)
    }

    const renderStateCell = (data, index, {path, typeKey}) => {
        const formatValue = data[path] ? translator({id: `${typeKey}.${data[path]}` }) : '-';
        return <span className='text-breakAll'>{formatValue}</span>
    };

    const viewOrEditStateInfo = (value) => {
        const {jurisdiction} = value
        setSelectedState(jurisdiction)
    };

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        const updatedSubmissionVM = setWizardDataToDraft(newSubmissionVM)
        updateWizardData(updatedSubmissionVM)
    }

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = useCallback((errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }, [updateValidationIssues]);

    const refreshStateSimpleInfo = async () => {
        setLoadingMask(true)
        const newSimpleStateInfos = await CPStateInformationService.getStateSimpleInfos(jobID, sessionUUID, authHeader)
        setLoadingMask(false)
        const newSubmissionVM = _.clone(submissionVM)
        _.set(newSubmissionVM.value, 'lobData.commercialProperty.coverables.stateSimpleInfos', newSimpleStateInfos)
        updateWizardData(newSubmissionVM)
    }

    const handleStateSpecificInfoClose = () => {
        setSelectedState(undefined)
        if (!isReadOnly) {
            refreshStateSimpleInfo()
        }
    }

    const onPageNext = async () => {
        setLoadingMask(true)
        const errorsAndWarnings = await CPStateInformationService.onStateInfoPageNext(jobID, sessionUUID, authHeader)
        const newSubmissionVM = setWizardDataToDraft(submissionVM)
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        setLoadingMask(false)
        return newSubmissionVM
    }

    //---------------------
    const overrideProps = {
        '@field': {
            // apply to all fields
            // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            labelPosition: 'left',
            readOnly: isReadOnly
        },
        stateInfoTable: {
            data: stateSimpleInfos,
            rowIdPath: 'jurisdiction'
        },
        stateInfoValidationIconCol: {
            renderCell: renderValidationCell,
        },
        viewOrEditLink: {
            label: isReadOnly ? messages.cpViewLabel : messages.cpViewAndEditLabel
        },
        stateSpecificInfoContainer: {
            visible: !!selectedState,
        },
        stateSpecificInfo: {
            selectedState: selectedState,
            wizardData: submissionVM,
            updateWizardDataWhileSetPeriodStatus: updateWizardDataWhileSetPeriodStatus,
            handleStateSpecificInfoClose,
        }
    };

    const resolvers = {
        resolveClassNameMap: {},
        resolveCallbackMap: {
            viewOrEditStateInfo,
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
            renderStateCell,
        },
        resolveComponentMap: {
            StateSpecificInfo: StateSpecificInfo
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            showPrevious={!selectedState}
            showCancel={!selectedState}
            showEntityNameInPageLevelIssues
            disableNext={selectedState}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={pageLevelValidation}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
        >
            <CoveragesConfigContext.Provider value={CPCoveragesConfig}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </CoveragesConfigContext.Provider>
        </WizardPage>
    );
}

CPStateSpecificInformationPage.propTypes = WizardPage.propTypes;
CPStateSpecificInformationPage.defaultProps = WizardPage.defaultProps;
export default CPStateSpecificInformationPage;