import { setComponentMapOverrides } from '@jutro/uiconfig';

import CRDummyPage from './pages/Dummy/CRDummyPage';
import CRDummyReadOnlyPage from './pages/Dummy/CRDummyPage.readonly';

import CRQuotePage  from './pages/Quote/CRQuotePage';
import CRQuoteReadOnlyPage from './pages/Quote/CRQuotePage.readonly';

import CRModifiersPage from './pages/Modifiers/CRModifiersPage';
import CRModifiersReadOnlyPage from './pages/Modifiers/CRModifiersPage.readonly';

import CRQualificationPage from './pages/Qualification/CRQualificationPage';
import CRQualificationReadOnlyPage from './pages/Qualification/CRQualificationPage.readonly';

setComponentMapOverrides(
    {
        CRDummyPage: {component: 'CRDummyPage'},
        CRDummyReadOnlyPage: { component: 'CRDummyReadOnlyPage' },

        //
        CRQuotePage: {component: 'CRQuotePage'},
        CRQuoteReadOnlyPage: {component: 'CRQuoteReadOnlyPage'},

        CRModifiersPage: {component: 'CRModifiersPage'},
        CRModifiersReadOnlyPage: {component: 'CRModifiersReadOnlyPage'},

        CRQualificationPage: {component: 'CRQualificationPage'},
        CRQualificationReadOnlyPage: {component: 'CRQualificationReadOnlyPage'},
    },
    {
        CRDummyPage,
        CRDummyReadOnlyPage,

        //
        CRQuotePage,
        CRQuoteReadOnlyPage,

        CRModifiersPage,
        CRModifiersReadOnlyPage,

        CRQualificationPage,
        CRQualificationReadOnlyPage
    }
);


export {
    CRDummyPage,
    CRDummyReadOnlyPage,
    //
    CRModifiersPage,
    CRModifiersReadOnlyPage,

    CRQualificationPage,
    CRQualificationReadOnlyPage
};

// eslint-disable-next-line import/prefer-default-export
export { default as CRSubmissionWizard } from './CRSubmissionWizard';
export { default as CRWizardPage } from './templates/CRWizardPage';

export { default as CRQuoteIssueRenderHelper } from './pages/Quote/util/CRQuoteIssueRenderHelper';
export { default as CRQuotePageConstants } from './pages/Quote/config/CRQuotePage.config';
