import { defineMessages } from 'react-intl';

export default defineMessages({
    planProductsTitle: {
        id: 'platform.component.products.Which line(s)/product(s) are you planning to quote under this account?',
        defaultMessage: 'Which line(s)/product(s) are you planning to quote under this account?'
    },
    otherProductsTitle: {
        id: 'platform.component.products.Also the following products',
        defaultMessage: 'Also the following products'
    },
    PA: {
        id: 'platform.component.products.Personal Auto',
        defaultMessage: 'Personal Auto'
    },
    PATooltip: {
        id: 'platform.component.products.Personal auto products including cars, trucks and SUV\'s',
        defaultMessage: 'Personal auto products including cars, trucks and SUV\'s'
    },
    HO: {
        id: 'platform.component.products.Homeowners',
        defaultMessage: 'Homeowners'
    },
    Home: {
        id: 'platform.component.products.Home',
        defaultMessage: 'Home'
    },
    HOTooltip: {
        id: 'platform.component.products.Personal home products, forms HO3, HO4, HO5, and HO6',
        defaultMessage: 'Personal home products, forms HO3, HO4, HO5, and HO6'
    },
    Dwelling: {
        id: 'platform.component.products.Dwelling',
        defaultMessage: 'Dwelling'
    },
    DwellingTooltip: {
        id: 'platform.component.products.Dwelling fire products including seasonal homes, second homes and owned rental dwellings',
        defaultMessage: 'Dwelling fire products including seasonal homes, second homes and owned rental dwellings'
    },
    PU: {
        id: 'platform.component.products.Umbrella',
        defaultMessage: 'Personal Umbrella'
    },
    PUTooltip: {
        id: 'platform.component.products.Personal liability umbrella',
        defaultMessage: 'Personal liability umbrella'
    },
    RV: {
        id: 'platform.component.products.Recreationc Vehicles',
        defaultMessage: 'Recreationc Vehicles'
    },
    RVTooltip: {
        id: 'platform.component.products.Recreational vehicle products for RV, camper, snow machine, ATV, UTV',
        defaultMessage: 'Recreational vehicle products for RV, camper, snow machine, ATV, UTV'
    },
    Motorcycle: {
        id: 'platform.component.products.Motorcycle',
        defaultMessage: 'Motorcycle'
    },
    MotorcycleTooltip: {
        id: 'platform.component.products.Personal cycle products including motorcycles and trikes',
        defaultMessage: 'Personal cycle products including motorcycles and trikes'
    },
    Watercraft: {
        id: 'platform.component.products.Watercraft',
        defaultMessage: 'Watercraft'
    },
    WatercraftTooltip: {
        id: 'platform.component.products.Watercraft including boats and motorized personal watercraft',
        defaultMessage: 'Our Watercraft policy offers coverage for boats, pontoons, personal watercraft, trailers, and lifts. Coverage can be customized to best suit your customer’s needs.'
    },
    RoadTrail: {
        id: 'platform.component.products.RoadTrail',
        defaultMessage: 'Road & Trail'
    },
    RoadTrailTooltip: {
        id: 'platform.component.products.RoadTrail Tooltip Placeholder',
        defaultMessage: 'Our Road & Trail policy offers coverage for motorcycles, ATVs, UTVs, snowmachines, snowmobiles, scooters, and self-balancing personal transporters. Coverage can be customized to best suit your customer’s needs.'
    },
    // ========================
// PRODUCT MAP LOB START Dummy
    Dummy: {
        id: 'wni.platform.component.products.Dummy',
        defaultMessage: 'Dummy Proudct'
    },
    DummyTooltip: {
        id: 'wni.platform.component.products.Dummy Tooltip Placeholder',
        defaultMessage: 'Dummy Product Tooltip'
    },
// PRODUCT MAP LOB END Dummy
    CA7CommAuto: {
        id: 'platform.component.products.Commercial Auto',
        defaultMessage: 'Commercial Auto'
    },
    CA7CommAutoTooltip: {
        id: 'platform.component.products.Commercial Auto Tooltip Placeholder',
        defaultMessage: 'Select here to quote Commercial Auto'
    },
// PRODUCT MAP LOB START WCMWorkersComp
    WCMWorkersComp: {
        id: 'wni.platform.component.products.WCMWorkersComp',
        defaultMessage: 'Workers Compensation'
    },
    WCMWorkersCompTooltip: {
        id: 'wni.platform.component.products.WCMWorkersComp Tooltip Placeholder',
        defaultMessage: 'Select here to quote Workers Compensation'
    },
// PRODUCT MAP LOB END WCMWorkersComp
// PRODUCT MAP LOB START GeneralLiability
    GeneralLiability: {
        id: 'wni.platform.component.products.GeneralLiability',
        defaultMessage: 'General Liability'
    },
    GeneralLiabilityTooltip: {
        id: 'wni.platform.component.products.GeneralLiability Tooltip Placeholder',
        defaultMessage: 'Select here to quote General Liability'
    },
// PRODUCT MAP LOB END GeneralLiability
// PRODUCT MAP LOB START CP7CommercialProperty
    CP7CommercialProperty: {
        id: 'wni.platform.component.products.CP7CommercialProperty',
        defaultMessage: 'Commercial Property'
    },
    CP7CommercialPropertyTooltip: {
        id: 'wni.platform.component.products.CP7CommercialProperty Tooltip Placeholder',
        defaultMessage: 'Select here to quote Commercial Property'
    },
// PRODUCT MAP LOB END CP7CommercialProperty
// PRODUCT MAP LOB START InlandMarine
    InlandMarine: {
        id: 'wni.platform.component.products.InlandMarine',
        defaultMessage: 'Inland Marine'
    },
    InlandMarineTooltip: {
        id: 'wni.platform.component.products.InlandMarine Tooltip Placeholder',
        defaultMessage: 'Select here to quote Inland Marine'
    },
// PRODUCT MAP LOB END InlandMarine
// PRODUCT MAP LOB START CommercialPackage
    CommercialPackage: {
        id: 'wni.platform.component.products.CommercialPackage',
        defaultMessage: 'Commercial Package'
    },
    CommercialPackageTooltip: {
        id: 'wni.platform.component.products.CommercialPackage Tooltip Placeholder',
        defaultMessage: 'Select here to quote Commercial Package'
    },
// PRODUCT MAP LOB END CommercialPackage
// PRODUCT MAP LOB START CR7Crime
    CR7Crime: {
        id: 'wni.platform.component.products.CR7Crime',
        defaultMessage: 'CR7Crime Proudct'
    },
    CR7CrimeTooltip: {
        id: 'wni.platform.component.products.CR7Crime Tooltip Placeholder',
        defaultMessage: 'CR7Crime Product Tooltip'
    },
// PRODUCT MAP LOB END CR7Crime
// PRODUCT MAP LOB SECTION ANCHOR
});