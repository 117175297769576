import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React, { useContext } from 'react';
import { useWniModal } from 'wni-components-platform-react';
import { GLExposureService } from 'wni-capability-quoteandbind-gl';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './ExposureDetails.metadata.json5';
import FieldSetMap from './ExposureFieldMap';
import SearchModal from '../SearchModal/SearchModal';

function ExposureDetails(props) {
    const modalApi = useWniModal();
    const {
        currentRow,
        writeValue: onValueChange,
        generateValidationIssues = _.noop,
        syncWizardData = _.noop,
        syncWizardDataSnapshot = _.noop,
        onValidate,
        showErrors,
        readOnly,
        extendProps: { jobID, sessionUUID, authHeader },
    } = props;


    const { publicID, locationMaps = [] } = currentRow.value;

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const generateOptions = () => {
        return locationMaps.map((item) => {
            return {
                code: item.publicID,
                name: item.displayName,
            };
        });
    };

    const handleSearch = async(propertyName, searchData, isDefault = false) => {
        setLoadingMask(true);
        const paramsData = {
            publicID,
            propertyName: propertyName,
            isDefault: isDefault,
            ...searchData
        };
        const res = await GLExposureService.getSearchClassCode(jobID, sessionUUID, paramsData, authHeader);
        setLoadingMask(false);
        return res;
    };


    const handleSelect = async(propertyName, item) => {
        setLoadingMask(true);
        const paramsData = {
            publicID,
            row: item,
            propertyName: propertyName
        }
        const res = await GLExposureService.handleSearchClassCode(jobID, sessionUUID, paramsData, authHeader);
        setLoadingMask(false);
        return res.exposure;
    };

    const onSearch = async(fieldItem) => {
        setLoadingMask(true);
        const res = await handleSearch(fieldItem.propertyName, {}, true);
        setLoadingMask(false);
        const componentProps = {
            title: fieldItem.label,
            actionBtnLabel: translator(commonMessages.ok),
            cancelBtnLabel: translator(commonMessages.cancelModel),
            publicID,
            initData: res,
            fieldItem,
            handleSearch,
            handleSelect
        }
        modalApi.showModal(<SearchModal {...componentProps} />).then((result) => {
            _.set(currentRow, 'value', result);
            syncWizardDataSnapshot(currentRow)
        }).catch(() => _.noop());
        
    };

    const updateService = async (serviceData) => {
        setLoadingMask(true);
        const res = await GLExposureService.postOnChangeAction(
            jobID,
            sessionUUID,
            serviceData,
            authHeader
        );
   
        _.set(currentRow, 'value', res.exposure);
        syncWizardDataSnapshot(currentRow);
        generateValidationIssues(res.errorsAndWarnings);
        setLoadingMask(false);
       
    };
    const onBlur = () => {
        updateService(currentRow.value);
    };

    const onFieldMapChange = (fieldItem, path, fieldModel = {}) => {
        const initCurrentRow = viewModelService.clone(currentRow);
        _.set(initCurrentRow.value, path, fieldItem);

        if (fieldModel.triggerFunc === 'onValueChange') {
            updateService(initCurrentRow.value);
        } else {
            syncWizardData(initCurrentRow);
        }
    };

    const writeValue = (value, path) => {
        const initCurrentRow = viewModelService.clone(currentRow);
        switch(path){
            case 'location.publicID':
                // if location change, classcode need be clean
                _.set(initCurrentRow, path, value);
                syncWizardData(initCurrentRow);
                updateService(initCurrentRow.value);       
                break;
            default:
                _.set(initCurrentRow, path, value);
                syncWizardData(initCurrentRow);
                break;
        }
        
    };

    const overrideProps = {
        location: {
            readOnly,
            availableValues: generateOptions(),
        },
        displayables: {
            vm: currentRow,
            dataPath: 'displayables',
            onValueChange: onFieldMapChange,
            onValidate,
            onBlur,
            onSearch,
            showErrors,
            readOnly,
        },
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {
            fieldsetmap: FieldSetMap,
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            showErrors={showErrors}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

export default ExposureDetails;
