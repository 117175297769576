import {
    useState,
    useCallback,
    useMemo,
    useEffect,
} from 'react';
import _, { update } from 'lodash';
import { resolveComponentFromName } from '@jutro/uiconfig';
import { WizardStepUtil } from 'wni-portals-util-js';

import { WizardConstants } from 'wni-portals-config-js';

/**
 * Attempts to resolve the string component
 * used in the wizard step. If no match is found or if the `component`
 * is left unchanged
 *
 * @param {WizardStep} stepDefinition a step definition
 * @returns {WizardStep}
 */
function getStepToRender(stepDefinition) {
    const { component, stepProps = {}, ...otherStepDefinitions } = stepDefinition;
    const { template } = stepProps;
    if (!_.isString(component)) {
        // component can be rendered already
        return stepDefinition;
    }
    const { component: resolvedComponent } = resolveComponentFromName(component) || { component };
    const { component: resolvedTemplate } = resolveComponentFromName(template)
        || { component: template };
    return {
        ...otherStepDefinitions,
        component: resolvedComponent,
        stepProps: {
            ...stepProps,
            template: resolvedTemplate
        }
    };
}

/**
 * Increments the `currentStepIndex` without overflowing
 *
 * @param {number} currentStepIndex the current index
 * @param {number} numOfSteps the total number of steps
 * @returns {number} the incremented number
 */
function nextIndex(currentStepIndex, numOfSteps) {
    return _.min([currentStepIndex + 1, numOfSteps - 1]);
}

/**
 * Decrements the `currentStepIndex` without overflowing
 * @param {number} currentStepIndex the current index
 * @returns {number} the decremented number
 */
function previousIndex(currentStepIndex) {
    return _.max([currentStepIndex - 1, 0]);
}

/**
 * Retuns a safe index to jump to.
 * A safe index is one smaller or equal to the `furthestIndexVisited` and greather
 * or equal than 0.
 * If the index is not in range the current step index is returned
 * @param {number} index the step to which to jump to
 * @param {number} currentStepIndex the current step index
 * @param {number} furthestIndexVisited the furthest step already visited
 * @returns {number} a checked index to jump to or the current index
 */
function jumpToIndex(index, currentStepIndex, furthestIndexVisited) {
    const isInRange = _.range(0, furthestIndexVisited + 1) // range is not inclusive
        .includes(index);
    return isInRange ? index : currentStepIndex;
}

/**
 * Replaces steps that are after a certain index with the given ones
 * 
 * Mec: this is poor design. Do not used it, please
 * 
 * @param {Array} wizardSteps the current steps for the wizard
 * @param {number} currentStepIndex the index of the current step
 * @param {Array} nextSteps the steps used to replace the existing steps that are
 *                          after the current index
 * @returns {Array}
 * @deprecated replaced by updateStepsVisibility()
 */
function replaceSteps(wizardSteps = [], currentStepIndex, nextSteps) {
    return _(wizardSteps)
        .take(currentStepIndex + 1) // index is 0 based
        .value()
        .concat(nextSteps);
}

// Go through wiardSteps and update stepVisibility to false if it is 
// not enlisted in nextSteps
function updateStepsVisibility(wizardSteps = [], currentStepIndex, nextSteps) {
    for (let i = currentStepIndex; i < wizardSteps.length; ++i) {
        let step = wizardSteps[i];
        const stepIncluded = nextSteps.find((ns) => ns.id === step.id);
        if (!stepIncluded && step[WizardConstants.stepVisible]) {
            step[WizardConstants.stepVisible] = false;
        }
    }
}

/**
 * @typedef {Object} WizardStep
 * @property {string} path the relative path at which this step will render
 * @property {string} id the id for the step
 * @property {Object} component the React component to render for this step
 * @property {string} title a string/displayKey describing the title of the page
 * @property {boolean} [visited] whethera step has been visited or not
 * @property {boolean} [visited] whethera step has been submitted or not
 * @property {Object} [stepProps] an object that is passed to the step as properties
 */

/**
 * Returns an array of wizard steps including whether a step has been visited or not
 * @param {Array<WizardStep>} steps the steps in the wizard
 * @param {number} furthestIndexVisited the index of the furthest step in the wizard already visited
 * @param {number} furthestIndexSubmitted the index of the furthest step
 *                                      in the wizard that has been already submitted
 * @returns {Array<WizardStep>}
 */
function withAccessInfo(steps, furthestIndexVisited, furthestIndexSubmitted) {
    const retval = steps.map((step, index) => ({
        ...step,
        indexId: index,
        visited: index <= furthestIndexVisited,
        submitted: _.isNil(furthestIndexSubmitted) ? false : index <= furthestIndexSubmitted
    }));
    return retval;
}

/**
 * @callback parameterlessCallback a function taking no parameters
 *
 * @callback jumpToCallback a function to jump to the next step
 * @param {number} index the index to which to jump to
 *
 * @callback changeNextStepsCallback a function to replace the nexts steps in a wizard
 * @param {Array<WizardStep>} nextSteps steps that should replace those after the current step
 */

/**
 * @typedef {Object} UseWizardStepsHook
 * @property {Array<WizardStep>} steps of the wizard
 * @property {WizardStep} currentStep current step
 * @property {number} currentStepIndex index of the current step
 * @property {parameterlessCallback} goNext function to go to the next step. Index must be
 *                                          between 0 and the furthest step visited
 * @property {parameterlessCallback} goPrevious function to go to the previous step
 * @property {jumpToCallback} jumpTo function to jump to a visited step
 * @property {changeNextStepsCallback} changeNextSteps function to change what are the next steps
 *                                                     in a wizard
 * @property {parameterlessCallback} markStepSubmitted marks a step as submitted
 * @property {parameterlessCallback} clearVisitedStepsAfterCurrent function to clear the visited
 *                                                          status for steps after the current one
 * @property {boolean} isSkipping whether the wizard is skipping pages (goes forward until stopped)
 * @property {parameterlessCallback} stopSkipping a function to stop the wizard from skipping
 */


function flattenWizarSteps (steps, sectionTitleStep) {
    return WizardStepUtil.flattenWizarSteps(steps, sectionTitleStep);
}

function filterVisibleSteps(steps) {
    return WizardStepUtil.filterVisibleSteps(steps);
}

/**
 * Sample usage:
 * normalizeWizardStepIndex(
 *   filterVisibleSteps(wizardSteps),
 *   currentStepIndex,
 *   wizardSteps[0],
 *   //
 *   filterVisibleSteps(newWizardSteps),
 *   0,
 *   //
 *   updateCurrentStepIndex;
 * );
 * @param {array{}} wizardSteps 
 * @param {*} currentStepIndex 
 * @param {*} fallbackStep 
 * @param {*} newWizardSteps 
 * @param {*} fallbackNewStepIndex 
 * @param {*} updateStepIndex 
 * @return {int} 
 */
function normalizeWizardStepIndex(
    wizardSteps,
    currentStepIndex,
    fallbackStep,
    //
    newWizardSteps,
    fallbackNewStepIndex,
    //
    updateStepIndex
) {
    const currentStep = wizardSteps[currentStepIndex] || fallbackStep;
    let newCurrentStepIndex = newWizardSteps.findIndex((step) => step.id === currentStep.id);
    if (newCurrentStepIndex < 0 ) {
        newCurrentStepIndex = fallbackNewStepIndex;
    }
    updateStepIndex(newCurrentStepIndex);
    return newCurrentStepIndex;
}
/**
 * Returns fields to manage
 * @param {Array<WizardStep>} initialSteps the initial steps of this wizard
 * @param {boolean} initialSkipState the initial state for the
 * @returns {UseWizardStepsHook} all the hooks parameters
 */
function useBaseWizardSteps(initialStepsParam, initialSkipState = false) {
    const initialSteps = useMemo(() => flattenWizarSteps(initialStepsParam), [initialStepsParam]);
    
    // const [internalWizardSteps = initialSteps, updateInternalWizardSteps] = useState(undefined);
    const [wizardSteps = initialSteps, updateWizardSteps] = useState(undefined);

    const [currentStepIndex, updateCurrentStepIndex] = useState(0);
    const [furthestIndexVisited, updateFurthestIndexVisited] = useState(0);
    const [furthestIndexSubmitted, updateFurthestIndexSubmitted] = useState(undefined);


    const [isSkipping = initialSkipState, updateSkipState] = useState(undefined);

    useEffect(() => {
        updateWizardSteps(initialSteps);
        
        // check if the total number of visible steps has been shortened
        const newVisibleSteps = filterVisibleSteps(initialSteps);
        
        // Hmm, not a solid solution after stepVisible has been introduced
        // const newTotalSteps = newVisibleSteps.length;
        // if (newTotalSteps <= currentStepIndex) {
        //     const newIndex = newTotalSteps - 1;
        //     updateCurrentStepIndex(newIndex);
        // }

        // Try this one instead, or simply use normalizeWizardStepIndex();
        const visibleSteps = filterVisibleSteps(wizardSteps);
        const currentStep = visibleSteps[currentStepIndex] || visibleSteps[0];
        let newCurrentStepIndex = newVisibleSteps.findIndex((step) => step.id === currentStep.id);
        if (newCurrentStepIndex < 0 ) {
            newCurrentStepIndex = 0;
        }
        updateCurrentStepIndex(newCurrentStepIndex);
    // Note: check ONLY change of initialSteps, not currentStepIndex
    }, [initialSteps]);

    
    const visibleWizardSteps = filterVisibleSteps(wizardSteps);
    const numOfSteps = visibleWizardSteps.length;

    /*
        Transitions
     */
    const goNext = useCallback(
        () => {
            const newIndex = nextIndex(currentStepIndex, numOfSteps);
            updateCurrentStepIndex(newIndex);
            updateFurthestIndexVisited(_.max([furthestIndexVisited, newIndex]));
        },
        [currentStepIndex, numOfSteps, furthestIndexVisited]
    );

    const goPrevious = useCallback(
        () => {
            const newIndex = previousIndex(currentStepIndex);
            updateCurrentStepIndex(newIndex);
        },
        [currentStepIndex]
    );

    const jumpTo = useCallback(
        (index, furthestIndex = undefined) => {
            const newFurthestIndex = furthestIndex || furthestIndexVisited;
            const newIndex = jumpToIndex(index, currentStepIndex, newFurthestIndex);
            updateCurrentStepIndex(newIndex);
        },
        [currentStepIndex, furthestIndexVisited]
    );

    /**
     * Change the following steps and mark them as unvisited
     */
    const changeNextStepsInternal = useCallback(
        (nextSteps, markFollowingStepsUnvisited = false) => {
            // updateWizardSteps(replaceSteps(wizardSteps, currentStepIndex, nextSteps));
            updateStepsVisibility(wizardSteps, currentStepIndex, nextSteps);
            if (markFollowingStepsUnvisited) {
                updateFurthestIndexVisited(currentStepIndex);
            }
        },
        [wizardSteps, currentStepIndex]
    );

    /**
     * Change steps
     * 
     * Mec: this is bad design inherited from OOTB. Do not use it in following products.
     */
    const changeNextSteps = useCallback(
        (nextSteps) => {
            changeNextStepsInternal(nextSteps, true);
        },
        [changeNextStepsInternal]
    );

    /*
        Access
     */
    const clearVisitedStepsAfterCurrent = useCallback(
        () => {
            updateFurthestIndexVisited(currentStepIndex);
            if (furthestIndexSubmitted !== undefined) {
                updateFurthestIndexSubmitted(
                    (currentFurtherIndexSubmitted) => _.min(
                        [currentFurtherIndexSubmitted, currentStepIndex]
                    )
                );
            }
        },
        [currentStepIndex, furthestIndexSubmitted]
    );

    const stepsToRender = useMemo(
        () => {
            const retval = visibleWizardSteps.map(getStepToRender);
            return retval;
        },
        [visibleWizardSteps]
    );

    const stepsWithDetails = useMemo(
        () => {
            const retval = withAccessInfo(stepsToRender, furthestIndexVisited,
                furthestIndexSubmitted);
            return retval;
        },
        [stepsToRender, furthestIndexVisited, furthestIndexSubmitted]
    );

    const markStepSubmitted = useCallback(
        () => {
            // Update step.submitted
            updateFurthestIndexSubmitted(
                (currentFurtherIndexSubmitted) => _.max(
                    [currentFurtherIndexSubmitted, currentStepIndex]
                )
            );
            // // Update step.visited as well
            // updateFurthestIndexVisited(
            //     (currentFurthestIndex) => _.max(
            //         [currentFurthestIndex, currentStepIndex]
            //     )
            // );
        },
        [currentStepIndex]
    );

    /*
        Skipping
     */
    const stopSkipping = useCallback(
        () => {
            updateSkipState(false);
        },
        []
    );

    /**
     * Specify the furtest page that has been visited
     *
     * Usage Example:
     * updateFurthestPageVisited('PAQuotePage');
     *
     * @param {string | number} PageID or PageIndex
     */
    const updateFurthestPageVisited = useCallback(
        (pageIdOrIndex) => {
            const pageIndex = WizardStepUtil.getWizardPageIndex(pageIdOrIndex, visibleWizardSteps);
            if (pageIndex >= 0) {
                updateFurthestIndexVisited(pageIndex);
            }
        },
        [visibleWizardSteps, currentStepIndex, updateFurthestIndexSubmitted]
    );

    /**
     * Mark the pages after pageIdOrIndex as unvisited
     *
     * USAGE EXAMPLE:
     * clearVisitedStepsAfterPage(4)
     *
     * @param pageIdOrIndex
     */
    const clearVisitedStepsAfterPage = useCallback(
        (pageIdOrIndex) => {
            const pageIndex = WizardStepUtil.getWizardPageIndex(pageIdOrIndex, visibleWizardSteps);
            if (pageIndex < 0 || pageIndex > furthestIndexVisited) {
                return;
            }
            updateFurthestIndexVisited(pageIndex);
            if (furthestIndexSubmitted !== undefined) {
                updateFurthestIndexSubmitted(
                    (currentFurtherIndexSubmitted) => _.min(
                        [currentFurtherIndexSubmitted, pageIndex]
                    )
                );
            }
        },
        [currentStepIndex, furthestIndexVisited, furthestIndexSubmitted, visibleWizardSteps]
    );

    /**
     * Three things done here:
     * 1) Filter out steps
     * 2) Update stepVisible status of these steps
     * 3) Update currentStepIndex, furthestIndexSubmitted and furthestIndexVisited
     */
    const toggleStepDisplayWithFilterFunc = useCallback((stepFilterFunc, showPageFunc = _.stubTrue, optConfigs = {}) => {
        let freshWizardSteps = wizardSteps;

        // fix the switch start PolicyChangeMode to default PolicyChangeMode
        if (!_.isEmpty(optConfigs.wizardSteps)) {
            freshWizardSteps = flattenWizarSteps(optConfigs.wizardSteps);
        }
        
        // store current wizard status
        const visibleSteps = filterVisibleSteps(wizardSteps);
        const currentStep = visibleSteps[currentStepIndex] || visibleSteps[0];
        const furthestStepSubmitted = visibleSteps[furthestIndexSubmitted] || currentStep;
        const furthestStepVisited = visibleSteps[furthestIndexVisited] || currentStep;

        // Go through initial steps, and check the filter criteria
        // Update 'visible' property of steps filtered out
        const newWizardSteps = freshWizardSteps.map((step) => {
            const stepMatchResult = stepFilterFunc(step);
            if (stepMatchResult) {
                step[WizardConstants.stepVisible] = showPageFunc(step, stepMatchResult);
            }
            return step;
        });

        // update three indices: currentStepIndex, furthestIndexVisited, furthestIndexSubmitted
        const newVisibleSteps = filterVisibleSteps(newWizardSteps);
        let newCurrentStepIndex = newVisibleSteps.findIndex((step) => step.id === currentStep.id);
        let newFurthestIndexSubmitted = newVisibleSteps.findIndex((step) => step.id === furthestStepSubmitted.id);
        let newFurthestIndexVisited = newVisibleSteps.findIndex((step) => step.id === furthestStepVisited.id);

        if (newCurrentStepIndex < 0) {
            newCurrentStepIndex = 0;
        }
        if (newFurthestIndexSubmitted < 0) {
            newFurthestIndexSubmitted = currentStepIndex;
        }
        if (newFurthestIndexVisited < 0) {
            newFurthestIndexVisited = currentStepIndex;
        }

        updateCurrentStepIndex(newCurrentStepIndex);
        updateFurthestIndexVisited(newFurthestIndexVisited);
        updateFurthestIndexSubmitted(newFurthestIndexSubmitted);
        //
        updateWizardSteps(newWizardSteps);

    }, [wizardSteps, currentStepIndex, furthestIndexSubmitted, furthestIndexVisited]);

    /**
     * Toggle single step display base on pageId or pageIndex.
     * 
     * Notes:
     * 1, The pageIndex is checked against initial Steps instead of current Steps.
     * 2, This method only works on a single page in a following position. For multiple
     *   pages, please introduce a new method.
     * 3, This method does not mark following steps as unvisited. If there is such need, 
     *   you can follow toggleStepDisplay() with clearVisitedStepsAfterCurrent().
     * 4, 
     * 
     * Sample Usage:
     * toggleStepDisplay('PaymentPage', true);
     * toggleStepDisplay('PaymentPage', false);
     * toggleStepDisplay(5, true);
     * toggleStepDisplay(5, false);
     * 
     * Sample Usage since R6:
     * toggleStepDisplay((step) => step[WizardConstants.parentId] === 'GLPages', true);
     * toggleStepDisplay((step) => step.id == 'CPPDummyPage');
     */
    const toggleStepDisplay_Deprecated = useCallback((pageIdOrIndex, showPageFunc = _.stubTrue) => {
        if (_.isFunction(pageIdOrIndex)) {
            toggleStepDisplayWithFilterFunc(pageIdOrIndex /* first param as stepFilterFunc */, showPageFunc);
            return;
        }

        // ==================backward compatibility for turnOn/OffWizardPage============
        let pageIndex = pageIdOrIndex;
        if (_.isString(pageIdOrIndex)) {
            pageIndex = initialSteps.findIndex((step) => step.id === pageIdOrIndex);
        }
        
        // turnOn/OffWizardPage updates only single step after current page
        if ( pageIndex <= currentStepIndex) {
            return;
        }

        const pageToUpdate = initialSteps[pageIndex];
        if (!pageToUpdate) { // page does not exist
            return;
        }

        const showPage = showPageFunc();
        if ( // page already visible or hidden
            (showPage && pageToUpdate[WizardConstants.stepVisible]) ||
            (!showPage && !pageToUpdate[WizardConstants.stepVisible])
        ){
            return;
        }

        toggleStepDisplayWithFilterFunc((step) => step.id === pageToUpdate.id, showPageFunc);
        
        // let nextSteps;
        // // let shouldUpdateNextSteps = false;
        // if (showPage) {
        //     // No need to update next steps when the page is already shown
        //     if (stepsWithDetails.length < initialSteps.length) {
        //         // shouldUpdateNextSteps = true;
        //         nextSteps = initialSteps.slice(currentStepIndex + 1);
        //     }
        // // else if (!showPage && stepsWithDetails.length === initialSteps.length)
        // } else if (stepsWithDetails.length === initialSteps.length) {
        //     // shouldUpdateNextSteps = true;
        //     const fromCurrentToTargetPage = initialSteps.slice(currentStepIndex + 1, pageIndex);
        //     const fromTargetPageOn = initialSteps.slice(pageIndex + 1);
        //     nextSteps = [...fromCurrentToTargetPage, ...fromTargetPageOn];
        // }

        // // if (shouldUpdateNextSteps) {
        // if (nextSteps) {
        //     // changeNextSteps(nextSteps);
        //     changeNextStepsInternal(nextSteps, false);
        // }
    }, [toggleStepDisplayWithFilterFunc, visibleWizardSteps, currentStepIndex, initialSteps, stepsWithDetails, changeNextSteps]);


    // 
    /**
     * Sample input: {[WizardConstants.stepId]: 'CPPDummyPage', [WizardConstants.parentId]: 'CPPGLPages', [WizardConstants.stepVisible]: true }
     *
     * Sample 2:
     * toggleWizardPageDisplay([
     *      {[WizardConstants.stepId]: 'CPPQualificationPage', [WizardConstantns.stepVisible]: true},
     *      {[WizardConstants.parentId]: 'CPP_GL', [WizardConstants.stepVisible]: false]},
     *      {[WizardConstants.stepId]: 'CPPLocation', [WizardConstantns.stepVisible]: true},
     *      {[WizardConstants.parentId]: 'CPP_IM', [WizardConstantns.stepVisible]: false}
     *  ]);
     */
    const toggleStepDisplay = useCallback((stepConfigs /* config array*/, optConfigs = undefined) => {
        if (_.isEmpty(stepConfigs)) {
            return;
        }
        //
        toggleStepDisplayWithFilterFunc((step) => {
            const stepMatchResult = stepConfigs.find((stepConfig) => {
                const {
                    [WizardConstants.stepId]: stepId,
                    [WizardConstants.parentId]: parentId,
                    // [WizardConstants.stepVisible]: stepVisible,
                } = stepConfig;
                
                let stepMatch = false;
                if (stepId) {
                    stepMatch = stepId === step.id;
                } else if (parentId)  {
                    stepMatch = parentId === step[WizardConstants.parentId];
                }
                return stepMatch;
            });
            return stepMatchResult;
        }, (step, stepMatchResult) => {
            const {
                [WizardConstants.stepVisible]: stepVisible,
            } = stepMatchResult;
            return stepVisible;
        }, optConfigs);
    }, [toggleStepDisplayWithFilterFunc]);



    return {
        steps: stepsWithDetails,
        //
        currentStep: stepsWithDetails[currentStepIndex],
        currentStepIndex,
        //
        goNext,
        goPrevious,
        jumpTo,
        // =================OOTB method: deprecated in R6 BEGIN====================
        changeNextSteps,
        // =================OOTB method: deprecated in R6 END======================
        //
        markStepSubmitted,
        clearVisitedStepsAfterCurrent,
        isSkipping,
        stopSkipping,
        updateFurthestPageVisited,
        clearVisitedStepsAfterPage,
        //
        // clearVisitedStepsFromQuotePage,
        toggleStepDisplay_Deprecated,
        toggleStepDisplay,
    };
}

export default useBaseWizardSteps;
